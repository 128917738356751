<template>
  <div>
    <avue-form ref="form"
               v-if="form.businessState === 3"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="transactContractSellerSignState">
        {{ form.transactContractSellerSignState === 1 ? '未签订' : form.transactContractSellerSignState === 2 ? '已签订' : '/' }}
      </template>
      <template slot="transactContractBuyerSignState">
        {{ form.transactContractBuyerSignState === 1 ? '未签订' : form.transactContractBuyerSignState === 2 ? '已签订' : '/' }}
      </template>
      <template slot="transactContractFinalPrice"><div>{{ form.transactContractFinalPrice }} 万元</div></template>

      <!--    <template slot="menuForm">-->
      <!--      <el-button v-if="form.nodeThree === 1" type="info" disabled>确认并进入下一节点</el-button>-->
      <!--    </template>-->
    </avue-form>
    <div v-else>
      <div class="flex align-items justify-content-center marginBottom20">
        <el-button type="text" @click="handleViewContract">展示交易合同内容，点击可查看预览</el-button>
      </div>
      <div v-if="form.businessState === 2" class="flex align-items justify-content-center">
        <el-button type="primary" @click="handleExamine">审核</el-button>
      </div>
      <el-descriptions title="审核驳回" :column="1" v-if="form.businessState === 4">
        <el-descriptions-item label="驳回原因">{{ form.businessStateReason }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <ExamineThree v-if="showExamine" :show="showExamine" :request="examineRequest" :examineId="examineId" @close="handleExamineClose"/>

  </div>

</template>

<script>
import {contractView, transactExamine} from "@/api/contract/customer";
import ExamineThree from "./examineThree.vue";

export default {
  name: "nodeThree",
  components: {ExamineThree},
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '卖方',
            prop: 'partyAName'
          },
          {
            label: '买方',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '签订状态',
            prop: 'transactContractSellerSignState'
          },
          {
            label: '签订状态',
            prop: 'transactContractBuyerSignState'
          },
          {
            label: '房源成交价(万元)',
            prop: 'transactContractFinalPrice',
            span: 24,
            rules: [{
              required: true,
              message: "房源成交价",
              trigger: "blur"
            }]
          }
        ]
      },

      showExamine: false,
      examineRequest: transactExamine,
      examineId: ''
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      this.form = form
      this.loading = false;
    },
    handleViewContract(){
      if(!this.form.transactId){
        this.$message.warning('请先在app端中完成内容填写!')
        return
      }
      contractView({
        id: this.form.transactId
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.open(res.data.pageUrl, '_blank')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 审核
    handleExamine(){
      this.showExamine = true
      this.examineId = this.form.id
    },
    handleExamineClose(){
      this.showExamine = false
      console.log('关闭审核')
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>