<template>
  <div id="nodeFour">
  <avue-form ref="form"
             v-model="form"
             v-loading="loading"
             :option="formOption"
             :upload-error="uploadError"
             :upload-delete="uploadDelete"
             :upload-after="uploadAfter"
             :upload-before="uploadBefore"
             @submit="handleSubmit">
    <template slot="partyAName"><div>{{ form.partyAName }}<el-button type="text" class="marginLeft10">支付方</el-button></div></template>
    <template slot="partyBName"><div>{{ form.partyBName }}<el-button type="text" class="marginLeft10">支付方</el-button></div></template>
    <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
    <template slot="sellerPayType"><div>{{ form.sellerPayType === 1 ? '线下支付' : form.sellerPayType === 2 ? '线上支付' : '/' }}</div></template>
    <template slot="buyerPayType"><div>{{ form.buyerPayType === 1 ? '线下支付' : form.buyerPayType === 2 ? '线上支付' : '/' }}</div></template>
    <template slot="one"><div>服务费</div></template>
    <template slot="sellerPayAmount"><div>￥{{ form.sellerPayAmount }}</div></template>
    <template slot="buyerPayAmount"><div>￥{{ form.buyerPayAmount }}</div></template>
    <template slot="hr"><hr /></template>

    <template slot="sellerCommissionPay"><div>￥{{ form.sellerCommissionPay }}</div></template>
    <template slot="buyCommissionPay"><div>￥{{ form.buyCommissionPay }}</div></template>
    <template slot="two"><div>佣金</div></template>

    <template slot="hrTwo"><hr /></template>
  </avue-form>
  </div>
</template>

<script>
import {fourService,fourConfirm} from "@/api/contract/customer";

export default {
  name: "nodeFour",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '卖方',
            prop: 'partyAName'
          },
          {
            label: '买方',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '',
            prop: 'hr',
            labelWidth: 0,
            span: 24,
          },
          {
            label: '卖方支付方式',
            prop: 'sellerPayType'
          },
          {
            label: '买方支付方式',
            prop: 'buyerPayType'
          },
          {
            label: '费用项',
            prop: 'one'
          },
          {
            label: '卖方支付金额',
            prop: 'sellerPayAmount'
          },
          {
            label: '买方支付金额',
            prop: 'buyerPayAmount'
          },
          {
            label: '是否完成服务费支付',
            prop: 'isCompleteCommissionPay',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            rules: [{
              required: true,
              message: "选择是否完成服务费支付",
              trigger: "blur"
            }],
            disabled: false
          },
          {
            label: '卖方支付凭证',
            prop: 'sellerPayVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传3张',
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传卖方支付凭证",
              trigger: "blur"
            }],
            disabled: false
          },
          {
            label: '买方支付凭证',
            prop: 'buyerPayVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传3张',
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传卖方支付凭证",
              trigger: "blur"
            }],
            disabled: false
          },
          {
            label: '',
            prop: 'hrTwo',
            labelWidth: 0,
            span: 24,
            display: false
          },
          {
            label: '卖方支付金额',
            prop: 'sellerCommissionPay',
            display: false
          },
          {
            label: '买方支付金额',
            prop: 'buyCommissionPay',
            display: false
          },
          {
            label: '费用项',
            prop: 'two',
            display: false
          },
          {
            label: '卖方支付凭证',
            prop: 'sellerCommissionVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传3张',
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传卖方支付凭证",
              trigger: "blur"
            }],
            display: false
          },
          {
            label: '买方支付凭证',
            prop: 'buyCommissionVoucher',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传3张',
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传卖方支付凭证",
              trigger: "blur"
            }],
            display: false
          },
          {
            label: '是否完成服务费支付',
            prop: 'isCommissionPay',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            rules: [{
              required: true,
              message: "选择是否完成服务费支付",
              trigger: "blur"
            }],
            display: false
          }
        ]
      },
      uploadList: [], //上传图片数据列表
      setDone: null
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
    this.uploadList = this.formOption.column.filter(item => item.type === 'upload'); //获取上传组件
  },
  methods: {
    async setForm(form){
      // console.log('44444444444444',form)
      if(this.setDone){
        this.setDone();
      }
      const { nodeFour, isCompleteCommissionPay, isCommissionPay, ...rest } = form;

      if(nodeFour === 1 && isCompleteCommissionPay !== 1){
        this.formOption.submitText = '确认'
      }
      if(nodeFour === 1 && isCompleteCommissionPay === 1 && isCommissionPay !== 1){
        this.formOption.submitText = '确认并进入下一节点'
        await this.setDisabled()
      }
      if(nodeFour === 2){
        await this.setDisabled()
      }
      if(nodeFour === 2 && this.$route.query.type === 'edit'){
        this.formOption.submitBtn = form.nodeFour !== 2;
        this.formOption.disabled = form.nodeFour === 2;
      }
      this.form = {
        isCompleteCommissionPay: isCompleteCommissionPay === 1 ? [1] : [],
        isCommissionPay: isCommissionPay === 1 ? [1] : [],
        nodeFour: nodeFour,
        ...rest
      }
      this.loading = false;
    },
    setDisabled(){
      const isCompleteCommissionPay = this.findObject(this.formOption.column, 'isCompleteCommissionPay');
      const sellerPayVoucher = this.findObject(this.formOption.column, 'sellerPayVoucher');
      const buyerPayVoucher = this.findObject(this.formOption.column, 'buyerPayVoucher');

      isCompleteCommissionPay.disabled = true;
      sellerPayVoucher.disabled = true;
      buyerPayVoucher.disabled = true;

      const hrTwo = this.findObject(this.formOption.column, 'hrTwo');
      const sellerCommissionPay = this.findObject(this.formOption.column, 'sellerCommissionPay');
      const buyCommissionPay = this.findObject(this.formOption.column, 'buyCommissionPay');
      const two = this.findObject(this.formOption.column, 'two');
      const sellerCommissionVoucher = this.findObject(this.formOption.column, 'sellerCommissionVoucher');
      const buyCommissionVoucher = this.findObject(this.formOption.column, 'buyCommissionVoucher');
      const isCommissionPay = this.findObject(this.formOption.column, 'isCommissionPay');

      hrTwo.display = true;
      sellerCommissionPay.display = true;
      buyCommissionPay.display = true;
      two.display = true;
      sellerCommissionVoucher.display = true;
      buyCommissionVoucher.display = true;
      isCommissionPay.display = true;
    },
    handleSubmit(form,done){
      console.log(form)
      const { buyerPayVoucher,
        isCompleteCommissionPay,
        sellerPayVoucher,
        nodeFour,
        isCommissionPay,
        sellerCommissionVoucher,
        buyCommissionVoucher
      } = form;
      const completeCommissionPayIs = Number(isCompleteCommissionPay.toString())
      const CommissionPayIs = Number(isCommissionPay.toString())
      if(nodeFour === 1 && completeCommissionPayIs === 1 && CommissionPayIs !== 1){
        fourService({
          id: form.id,
          isCompleteCommissionPay: completeCommissionPayIs,
          sellerPayVoucher: sellerPayVoucher,
          buyerPayVoucher: buyerPayVoucher
        }).then(res=>{
          if(res.code === 200){
            this.$message.success('操作成功')
            this.$emit('nextStep',{
              currentNode: 4
            })
            this.setDone = done;
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }
      if(nodeFour === 1 && completeCommissionPayIs === 1 && CommissionPayIs === 1){
        fourConfirm({
          id: form.id,
          sellerCommissionVoucher: sellerCommissionVoucher,
          buyCommissionVoucher: buyCommissionVoucher,
          isCommissionPay: CommissionPayIs
        }).then(res=>{
          if(res.code === 200){
            this.$message.success('操作成功');
            this.$emit('nextStep',{
              currentNode: res.data.currentNode
            })
            this.setDone = null;
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      });
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.propsHttp.name){
          column.propsHttp.name += ',' + res.path
        }else{
          column.propsHttp.name = res.path;
        }
        if(column.propsHttp.name.split(',').length >= column.limit) {
          this.upload('none',column.prop);
        }
      }
    },
    upload(type,prop){
      let index = this.uploadList.findIndex(item => item.prop === prop)
      console.log(index)
      let upload = document.getElementById('nodeFour').getElementsByClassName('el-upload')[index];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    },
  }
}
</script>

<style scoped>
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>