<template>
  <div id="nodeTen">
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore"
               @submit="handleSubmit">
    </avue-form>
  </div>
</template>

<script>
import {tenConfirm} from "@/api/contract/customer";

export default {
  name: "nodeTen",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 170,
        span: 12,
        disabled: false,
        column: [
          {
            label: '是否完成过户',
            prop: 'isCompleteTransfer',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            row: true,
            rules: [{
              required: true,
              message: "选择是否完成过户",
              trigger: "blur"
            }]
          },
          {
            label: '说明',
            prop: 'completeTransferDesc',
            type: 'textarea',
            maxlength: 100,
            showWordLimit: true,
            row: true
          },
          {
            label: '房本照片',
            prop: 'houseBookPhotos',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            fileSize: 20 * 1024,
            limit: 3,
            rules: [{
              required: true,
              message: "请上传房本照片",
              trigger: "blur"
            }]
          }
        ]
      },
      uploadList: [], //上传图片数据列表
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
    this.uploadList = this.formOption.column.filter(item => item.type === 'upload'); //获取上传组件
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isCompleteTransfer, ...rest } = form;
      this.form = {
        isCompleteTransfer: isCompleteTransfer === 1 ? [1] : [],
        ...rest
      }
      if(this.$route.query.type === 'edit') {
        this.formOption.disabled = form.nodeTen === 2 || form.agentState === 2;
        this.formOption.submitBtn = form.nodeTen !== 2 && form.agentState !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      if(form.nodeFive === 1){
        this.$message.warning('缴纳定金节点未完成')
        return done();
      }else if(form.nodeSix === 1){
        this.$message.warning('网签及面签节点未完成')
        return done();
      }else if(form.nodeSeven === 1){
        this.$message.warning('缴纳首付节点未完成')
        return done();
      }else if(form.nodeEight === 1){
        this.$message.warning('解抵押节点未完成')
        return done();
      }
      tenConfirm({
        id: form.id,
        isCompleteTransfer: Number(form.isCompleteTransfer.toString()),
        completeTransferDesc: form.completeTransferDesc,
        houseBookPhotos: form.houseBookPhotos
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      });
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.propsHttp.name){
          column.propsHttp.name += ',' + res.path
        }else{
          column.propsHttp.name = res.path;
        }
        if(column.propsHttp.name.split(',').length >= column.limit) {
          this.upload('none',column.prop);
        }
      }
    },
    upload(type,prop){
      let index = this.uploadList.findIndex(item => item.prop === prop)
      let upload = document.getElementById('nodeTen').getElementsByClassName('el-upload')[index];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    }
  }
}
</script>

<style scoped>
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>