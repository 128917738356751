<template>
  <div id="nodeEleven">
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               @submit="handleSubmit">
    </avue-form>
  </div>
</template>

<script>
import {elevenConfirm} from "@/api/contract/customer";

export default {
  name: "nodeEleven",
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 170,
        span: 12,
        disabled: false,
        column: [
          {
            label: '是否物业交割',
            prop: 'isPropertyDelivery',
            type: 'checkbox',
            dicData: [
              { label: '确认完成', value: 1 }
            ],
            row: true,
            rules: [{
              required: true,
              message: "选择是否物业交割",
              trigger: "blur"
            }]
          },
          {
            label: '说明',
            prop: 'propertyDeliveryDesc',
            type: 'textarea',
            maxlength: 100,
            showWordLimit: true,
            row: true
          }
        ]
      }
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isPropertyDelivery, ...rest } = form;
      this.form = {
        isPropertyDelivery: isPropertyDelivery === 1 ? [1] : [],
        ...rest
      }
      if(this.$route.query.type === 'edit') {
        this.formOption.disabled = form.nodeEleven === 2 || form.agentState === 2;
        this.formOption.submitBtn = form.nodeEleven !== 2 && form.agentState !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      elevenConfirm({
        id: form.id,
        isPropertyDelivery: Number(form.isPropertyDelivery.toString()),
        propertyDeliveryDesc: form.propertyDeliveryDesc
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    }
  }
}
</script>

<style scoped>
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>