<template>
  <div class="main-content">
    <h3 class="marginBottom20">节点{{ type === 'edit' ? '编辑' : '详情' }}</h3>
    <div class="marginBottom10">
      <el-button size="medium" icon="el-icon-back" @click="handleBack">返回</el-button>
    </div>

    <el-card class="marginBottom20">
      <template #header>
        <h4>节点信息</h4>
      </template>
      <Steps :active="currentNode" finish-status="success" simple :space="30">
        <Step v-for="(item,index) in step" :key="index" :title="item.label" :value="item.value" @click.native="handleStep(index,item.name,item.value)"></Step>
      </Steps>
    </el-card>

<!--    <el-steps :active="currentNode" finish-status="success" simple class="marginBottom20" :space="30">-->
<!--      <el-step v-for="(item,index) in step" :key="index" :title="item.label" :value="item.value" @click.native="handleStep(index)"></el-step>-->
<!--    </el-steps>-->
    <el-card>
      <template #header>
        <h4 v-for="(item,index) in step" :key="index" v-show="currentName === item.name">{{ item.label }}</h4>
      </template>
      <NodeOne ref="nodeOne" v-show="currentName === 'nodeOne'" @nextStep="nextStep"/>
      <NodeTwo ref="nodeTwo" v-show="currentName === 'nodeTwo'" @change="handleChange"/>
      <NodeThree ref="nodeThree" v-show="currentName === 'nodeThree'" @change="handleChange"/>
      <NodeFour ref="nodeFour" v-show="currentName === 'nodeFour'" @nextStep="nextStep"/>
      <NodeFive ref="nodeFive" v-show="currentName === 'nodeFive'" @nextStep="nextStep"/>
      <NodeSix ref="nodeSix" v-show="currentName === 'nodeSix'" @nextStep="nextStep"/>
      <NodeSeven ref="nodeSeven" v-show="currentName === 'nodeSeven'" @nextStep="nextStep"/>
      <NodeEight ref="nodeEight" v-show="currentName === 'nodeEight'" @nextStep="nextStep"/>
      <NodeNine ref="nodeNine" v-show="currentName === 'nodeNine'" @nextStep="nextStep"/>
      <NodeTen ref="nodeTen" v-show="currentName === 'nodeTen'" @nextStep="nextStep"/>
      <NodeEleven ref="nodeEleven" v-show="currentName === 'nodeEleven'" @nextStep="nextStep"/>
      <NodeTwelve ref="nodeTwelve" v-show="currentName === 'nodeTwelve'" @nextStep="nextStep"/>
    </el-card>
  </div>
</template>

<script>
import { nodeSecondView } from "@/api/contract/customer"
import Steps from "./components/steps";
import Step from "./components/step";
import NodeOne from "./components/second/nodeOne"
import NodeTwo from "./components/second/nodeTwo"
import NodeThree from "./components/second/nodeThree"
import NodeFour from "./components/second/nodeFour"
import NodeFive from "./components/second/nodeFive"
import NodeSix from "./components/second/nodeSix"
import NodeSeven from "./components/second/nodeSeven"
import NodeEight from "./components/second/nodeEight"
import NodeNine from "./components/second/nodeNine"
import NodeTen from "./components/second/nodeTen"
import NodeEleven from "./components/second/nodeEleven"
import NodeTwelve from "./components/second/nodeTwelve"
export default {
  name: "nodeInfo",
  components:{
    Steps,
    Step,
    NodeOne,
    NodeTwo,
    NodeThree,
    NodeFour,
    NodeFive,
    NodeSix,
    NodeSeven,
    NodeEight,
    NodeNine,
    NodeTen,
    NodeEleven,
    NodeTwelve
  },
  data(){
    return{
      type: this.$route.query.type,
      step: [
        { label: '交易信息完善', value: 1, name: 'nodeOne' },
        { label: '签订居间合同', value: 1, name: 'nodeTwo' },
        { label: '签订买卖合同', value: 1, name: 'nodeThree' },
        { label: '缴纳服务费及佣金', value: 1, name: 'nodeFour' },
        { label: '缴纳定金', value: 1, name: 'nodeFive' },
        { label: '网签及面签', value: 1, name: 'nodeSix' },
        { label: '缴纳首付', value: 1, name: 'nodeSeven' },
        { label: '解抵押', value: 1, name: 'nodeEight' },
        { label: '缴税', value: 1, name: 'nodeNine' },
        { label: '过户', value: 1, name: 'nodeTen' },
        { label: '物业交割', value: 1, name: 'nodeEleven' },
        { label: '待归档', value: 1, name: 'nodeTwelve' }
      ], // 1 未完成  2 完成  3 当前
      info: {},
      currentNode: 0,
      currentName: 'nodeOne',
      loading: false,
    }
  },
  async mounted() {
    await this.nodeSecondView('onload');
  },
  methods: {
    async nodeSecondView(type){
      const res = await nodeSecondView({ id: this.$route.query.id });
      // console.log(res)
      // console.log('节点1是否完成',res.data.nodeOne)
      // console.log('节点2是否完成',res.data.nodeTwo)
      // console.log('节点3是否完成',res.data.nodeThree)
      // console.log('节点4是否完成',res.data.nodeFour)
      // console.log('节点5是否完成',res.data.nodeFive)
      // console.log('节点6是否完成',res.data.nodeSix)
      // console.log('节点7是否完成',res.data.nodeSeven)
      // console.log('节点8是否完成',res.data.nodeEight)
      // console.log('节点9是否完成',res.data.nodeNine)
      // console.log('节点10是否完成',res.data.nodeTen)
      // console.log('节点11是否完成',res.data.nodeEleven)
      // console.log('节点12是否完成',res.data.nodeTwelve)
      // console.log('currentNode',res.data.currentNode)
      if(res.code === 200){
        const { currentNode,...rest } = res.data;
        this.info = {
          currentNode: currentNode - 1,
          ...rest
        };
        if(type === 'onload'){
          this.currentNode = currentNode - 1;
          this.currentName = this.step[currentNode - 1].name
          console.log(this.currentName)
        }
        await this.setStep('nodeOne',res.data.nodeOne,currentNode - 1)
        await this.setStep('nodeTwo',res.data.nodeTwo,currentNode - 1)
        await this.setStep('nodeThree',res.data.nodeThree,currentNode - 1)
        await this.setStep('nodeFour',res.data.nodeFour,currentNode - 1)
        await this.setStep('nodeFive',res.data.nodeFive,currentNode - 1)
        await this.setStep('nodeSix',res.data.nodeSix,currentNode - 1)
        await this.setStep('nodeSeven',res.data.nodeSeven,currentNode - 1)
        await this.setStep('nodeEight',res.data.nodeEight,currentNode - 1)
        await this.setStep('nodeNine',res.data.nodeNine,currentNode - 1)
        await this.setStep('nodeTen',res.data.nodeTen,currentNode - 1)
        await this.setStep('nodeEleven',res.data.nodeEleven,currentNode - 1)
        await this.setStep('nodeTwelve',res.data.nodeTwelve,currentNode - 1)
        await this.$refs.nodeOne.setForm(res.data)
        await this.$refs.nodeTwo.setForm(res.data)
        await this.$refs.nodeThree.setForm(res.data)
        await this.$refs.nodeFour.setForm(res.data)
        await this.$refs.nodeFive.setForm(res.data)
        await this.$refs.nodeSix.setForm(res.data)
        await this.$refs.nodeSeven.setForm(res.data)
        await this.$refs.nodeEight.setForm(res.data)
        await this.$refs.nodeNine.setForm(res.data)
        await this.$refs.nodeTen.setForm(res.data)
        await this.$refs.nodeEleven.setForm(res.data)
        await this.$refs.nodeTwelve.setForm(res.data)
      }else{
        this.$message.error(res.msg)
      }

    },
    setStep(name,num,current){
      const index = this.step.findIndex(item=> item.name === name);
      // console.log(index)
      if(index === current && index !== this.step.length - 1){
        this.step[index].value = 3
      }else{
        this.step[index].value = num
      }
    },
    handleStep(e,name,value){
      // console.log(e,name,value)
      if(this.$route.query.type === 'edit'){
        if(this.info.currentNode === e){
          this.currentNode = e;
          this.currentName = name;
        }
      }
      if(e <= this.info.currentNode && this.currentNode !== e && value === 2){
        console.log(e)
        this.currentNode = e;
        this.currentName = name;
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 确定并进入下一节点
    async nextStep(e){
      await this.nodeSecondView('refresh')
      this.currentNode = e.currentNode - 1;
      this.currentName = this.step[e.currentNode - 1].name;
    },
    async handleChange() {
      await this.nodeSecondView('onload');
    }
  }
}
</script>

<style scoped>
/deep/ .el-steps{
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-steps--simple{
  padding: 13px 8% 0 !important;
}
/deep/ .el-steps .el-step{
  width: 300px !important;
  flex-basis: inherit !important;
  margin-bottom: 13px;
  cursor: pointer;
}
</style>