<template>
  <avue-form ref="form"
             v-model="form"
             v-loading="loading"
             :option="formOption"
             @submit="handleSubmit">
    <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
    <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
    <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
    <template slot="houseName"><div>{{ form.houseName }}</div></template>
    <template slot="houseCode"><div>{{ form.houseCode }}</div></template>
    <template slot="businessType"><div>{{ form.businessType === 1 ? '二手房交易' : '房屋租赁' }}</div></template>
    <template slot="buildName"><div>{{ form.buildName }}</div></template>
    <template slot="sellPrice"><div>{{ form.sellPrice }} 万元</div></template>
    <template slot="averagePrice"><div>{{ form.averagePrice }} 元/m²</div></template>
    <template slot="bedroom"><div>{{ form.bedroom }} 室 {{ form.office }} 厅</div></template>
    <template slot="floorage"><div>{{ form.floorage }} m²</div></template>
    <template slot="createTime"><div>{{ form.createTime }}</div></template>
  </avue-form>
</template>

<script>
import { oneConfirm } from "@/api/contract/customer"
export default {
  name: "nodeOne",
  data(){
    return{
      loading: false,
      form: {},
      formOption: {
        submitText: '确认并进入下一节点',
        submitBtn: true,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '卖方',
            prop: 'partyAName'
          },
          {
            label: '买方',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '房源名称',
            prop: 'houseName'
          },
          {
            label: '房源号',
            prop: 'houseCode'
          },
          {
            label: '业务类型',
            prop: 'businessType'
          },
          {
            label: '楼盘名称',
            prop: 'buildName'
          },
          {
            label: '总价',
            prop: 'sellPrice'
          },
          {
            label: '单价',
            prop: 'averagePrice'
          },
          {
            label: '户型',
            prop: 'bedroom'
          },
          {
            label: '建筑面积',
            prop: 'floorage'
          },
          {
            label: '发起时间',
            prop: 'createTime'
          },
          {
            label: '是否全款',
            prop: 'isFullPay',
            type: 'radio',
            dicData: [
              { label: '是', value: 1 },
              { label: '否', value: 2 }
            ],
            rules: [{
              required: true,
              message: "选择是否全款",
              trigger: "blur"
            }]
          },
          {
            label: '是否解抵押',
            prop: 'isReleaseMortgage',
            type: 'radio',
            dicData: [
              { label: '是', value: 1 },
              { label: '否', value: 2 }
            ],
            rules: [{
              required: true,
              message: "选择是否解抵押",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  mounted() {
    if(this.$route.query.type === 'view'){
      this.formOption.disabled = true;
      this.formOption.submitBtn = false;
    }
  },
  methods: {
    setForm(form){
      // console.log(form)
      const { isFullPay, isReleaseMortgage, ...rest } = form;
      this.form = {
        isFullPay: isFullPay === 0 ? '' : isFullPay,
        isReleaseMortgage: isReleaseMortgage === 0 ? '' : isReleaseMortgage,
        ...rest
      }
      if(this.$route.query.type === 'edit'){
        this.formOption.disabled = form.nodeOne === 2;
        this.formOption.submitBtn = form.nodeOne !== 2;
      }
      this.loading = false;
    },
    handleSubmit(form,done){
      // console.log(form)
      oneConfirm({
        id: form.id,
        isFullPay: form.isFullPay,
        isReleaseMortgage: form.isReleaseMortgage
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('nextStep',{
            currentNode: res.data.currentNode
          })
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    }
  }
}
</script>

<style scoped>

</style>