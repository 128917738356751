<template>
  <div>
    <avue-form ref="form"
               v-if="form.intermediaryState === 3"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="intermediaryContractSellerSignState">
        {{ form.intermediaryContractSellerSignState === 1 ? '未签订' : form.intermediaryContractSellerSignState === 2 ? '已签订' : '/' }}
      </template>
      <template slot="intermediaryContractBuyerSignState">
        {{ form.intermediaryContractBuyerSignState === 1 ? '未签订' : form.intermediaryContractBuyerSignState === 2 ? '已签订' : '/' }}
      </template>
      <!--    <template slot="menuForm">-->
      <!--      <el-button v-if="form.nodeTwo === 1" type="info" disabled>确认并进入下一节点</el-button>-->
      <!--    </template>-->
    </avue-form>
    <div v-else>
      <div class="flex align-items justify-content-center marginBottom20">
        <el-button type="text" @click="handleViewContract">展示居间合同内容，点击可查看预览</el-button>
      </div>
      <div v-if="form.intermediaryState === 2" class="flex align-items justify-content-center">
        <el-button type="primary" @click="handleExamine">审核</el-button>
      </div>
      <el-descriptions title="审核驳回" :column="1" v-if="form.intermediaryState === 4">
        <el-descriptions-item label="驳回原因">{{ form.intermediaryStateReason }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <Examine v-if="showExamine" :show="showExamine" :request="examineRequest" :examineId="examineId" @close="handleExamineClose"/>

  </div>
</template>

<script>
import {contractView, intermediaryExamine} from "@/api/contract/customer";
import Examine from "./examine";
export default {
  name: "nodeTwo",
  components: {Examine},
  data() {
    return {
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 170,
        span: 8,
        disabled: false,
        column: [
          {
            label: '卖方',
            prop: 'partyAName'
          },
          {
            label: '买方',
            prop: 'partyBName'
          },
          {
            label: '经纪人',
            prop: 'brokerName'
          },
          {
            label: '卖方签订状态',
            prop: 'intermediaryContractSellerSignState'
          },
          {
            label: '买方签订状态',
            prop: 'intermediaryContractBuyerSignState'
          }
        ]
      },

      showExamine: false,
      examineRequest: intermediaryExamine,
      examineId: ''
    }
  },
  methods: {
    setForm(form){
      console.log(form)
      this.form = form
      this.loading = false;
    },
    // 查看居间合同
    handleViewContract(){
      // console.log(this.form)
      if(!this.form.intermediaryId){
        this.$message.warning('请先在app端中完成内容填写!')
        return
      }
      contractView({
        id: this.form.intermediaryId
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.open(res.data.pageUrl, '_blank')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 审核
    handleExamine(){
      this.showExamine = true
      this.examineId = this.form.id
    },
    handleExamineClose(){
      this.showExamine = false
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>